import { errorType, ITask, METHODS, useAppStore, useAxios } from "@shared";
import { useCallback } from "react";
import { toast } from "sonner";
const development = process.env.NODE_ENV !== "production";
export const useProcessHook = () => {
  const { setToastId, setError, toastDismissId } = useAppStore();
  const { fetchData } = useAxios();
  async function* streaming(url: string) {
    try {
      const response = await fetch(url);
      const reader = response.body?.getReader();
      while (reader) {
        const { done, value } = await reader.read();
        if (done) break;

        yield value;
      }
    } catch (error) {
      yield new Uint8Array();
    }
  }

  const getTaskInfo = useCallback(
    async (taskId: string) => {
      const response = await fetchData<ITask>(
        `/task/${taskId}`,
        METHODS.GET,
        null,
        false
      );
      if (!Boolean(response.data)) return;

      if (response.data.data === "2") {
        return setError("Invalid zip file");
      }

      setToastId(response.data.title);

      for await (const data of streaming(
        `${development ? process.env.REACT_APP_URL : ""}/task/status/${taskId}`
      )) {
        const decoder = new TextDecoder();
        const status = decoder.decode(data, { stream: true });
        if (status === "1") {
          setToastId(null);
          window.location.reload();
        } else if (status === "2") {
          setToastId(null);
          toast.dismiss(toastDismissId);
          setToastId(errorType + "Invalid zip file");
        }
      }
    },
    [toastDismissId]
  );

  return { getTaskInfo };
};
