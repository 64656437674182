import { useAppStore } from "6-shared/store";
import { Spinner } from "@nextui-org/react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { toast } from "sonner";
type props = {
  text: string;
};

export const ProcessInfo = (props: props) => (
  <div className="flex w-full justify-between items-center">
    <span className="text-xl text-warning-500">{props.text}</span>{" "}
    <Spinner color="warning" />
  </div>
);

export const ProcessError = (props: props) => {
  const { toastDismissId, setToastDismissId, setToastId } = useAppStore();
  const handleClose = () => {
    toast.dismiss(toastDismissId);
    setToastDismissId(null);
    setToastId(null);
  };
  return (
    <div className="flex w-full justify-between items-center">
      <span className="text-xl text-red-500">{props.text}</span>
      <IoIosCloseCircleOutline
        className="text-2xl cursor-pointer"
        onClick={handleClose}
      />
    </div>
  );
};
